.canvas-context-menu.mantine-Dialog-root {
    padding: 0 !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px, rgba(0, 0, 0, 0.23) 0 3px 6px;
}

.canvas-context-menu > .mantine-NavLink-root {
    padding: 5px 0 5px 10px;
}

.action-button-context-menu.mantine-Dialog-root {
    padding: 0 !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px, rgba(0, 0, 0, 0.23) 0 3px 6px;
    background: var(--mantine-color-blue-5);
    color: white;

    :hover {
        color: white;
        background: var(--mantine-color-blue-7);
        border-radius: 5px;
    }
}

.action-button-context-menu > .mantine-NavLink-root {
    padding: 10px 10px 10px 10px;
}

/*.action-menu-context-menu.mantine-Dialog-root {
    padding: 0 !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px, rgba(0, 0, 0, 0.23) 0 3px 6px;
    background: var(--mantine-color-blue-5);
    color: white;
    :hover {
        color: white;
        background: var(--mantine-color-blue-7);
        border-radius: 5px;
    }
}

.action-menu-context-menu > .mantine-NavLink-root {
    padding: 10px 10px 10px 10px;
}*/


.action-menu-context-menu.mantine-Dialog-root {
    padding: 0 !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px, rgba(0, 0, 0, 0.23) 0 3px 6px;
}

.action-menu-context-menu > .mantine-NavLink-root {
    padding: 5px 10px 5px 10px;
}

div[data-lastpass-icon-root="true"] {
    opacity: 0 !important;
}

div[data-lastpass-infield="true"] {
    opacity: 0 !important;;
}