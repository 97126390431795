table.datatable {
    width: max-content;
    border-collapse: collapse;
    border: 1px solid var(--mantine-color-gray-5);
}

table.datatable th {
    white-space: nowrap;
    /*font-family: 'Fira Code';*/
    font-size: 14px;
    height: 30px;
    border: 1px solid var(--mantine-color-gray-5);
    padding-left: 3px;
    padding-right: 3px;
    text-align: center;
}

table.datatable td {
    white-space: nowrap;
    vertical-align: center;
    text-align: center;
    /*font-family: 'Fira Code';*/
    font-size: 14px;
    border: 1px solid var(--mantine-color-gray-5);
    padding-left: 5px;
    padding-right: 5px;
}

table.datatable tbody tr:hover {
    background: var(--mantine-color-gray-1);
}

.datatable td.negative {
    color: red;
}

table.datatable td.datatable-column-text {
    text-align: left;
}

table.datatable td.datatable-column-number {
    text-align: right;
}

table.datatable td.datatable-column-select {
    text-align: left;
}

table.datatable td.datatable-column-date {
    text-align: center;
}

table.datatable tr.datatable-last-row-clicked {
    background: var(--mantine-color-orange-0);
}

.datatable td.match-positive {
    background: var(--mantine-color-green-4);
}

.datatable td.match-negative {
    background: var(--mantine-color-red-4);
}

.filter-popover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}