table.datasheet {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-collapse: collapse;
    overflow: hidden;
}

table.datasheet:focus {
    outline: none;
}

.datasheet th {
    padding: 0 5px 0 5px;
    font-size: 14px;
    background: var(--mantine-color-blue-6);
    color: white;
    height: 30px;
    overflow: hidden;
    /*white-space: nowrap;*/
}

table.datasheet th:first-child {
    /*border-top-left-radius: 20px;*/
}

table.datasheet th:last-child {
    /*border-top-right-radius: 20px;*/
}

.datasheet td.lineNumberCol {
    padding: 0 5px 0 5px;
}

.datasheet tr.invalidValue {
    background: var(--mantine-color-red-2);
}

.datasheet td.invalidValue {
    background: var(--mantine-color-red-2);
}

.datasheet td {
    padding: 0;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    height: 22px;
}

.datasheet td.cellSelected {
    background: var(--mantine-color-blue-0);
}

.datasheet td input {
    padding: 0;
    font-size: 14px;
    border-radius: 0;
    border: 1px solid blueviolet;
    min-height: 22px !important;
    height: 22px !important;
}

.datasheet td .mantine-NumberInput-input {
    text-align: right;
}

.datasheet td.readOnly {
    color: var(--mantine-color-gray-6);
}

.datasheet-menu.mantine-Dialog-root {
    padding: 0 !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.datasheet-menu > .mantine-NavLink-root {
    padding: 5px 0 5px 10px;
}

/* Refactored */

table.datasheetV2 {
    border-collapse: collapse;
    outline: none;
    table-layout: fixed;
}

table.datasheetV2 tr {
    /*border-collapse: collapse;*/
    /*outline: thin solid;*/
}

table.datasheetV2 th {
    user-select: none;
    border-collapse: collapse;
    border: 1px solid var(--mantine-color-gray-3);
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
}

table.datasheetV2 th.readOnly {
    font-style: italic;
}

table.datasheetV2 td {
    user-select: none;
    border-collapse: collapse;
    font-family: 'Verdana', sans-serif, serif;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    border: 1px solid var(--mantine-color-gray-3);
    height: 25px;
}

table.datasheetV2 td.readOnly {
    color: var(--mantine-color-gray-6);
    font-style: italic;
}

table.datasheetV2 td.selected {
    background: var(--mantine-color-blue-1);
}

table.datasheetV2 td.editing {
    box-shadow: 0 0 0 10px var(--mantine-color-blue-6) inset;
}

table.datasheetV2 td.error {
    background: var(--mantine-color-red-2);
}

.datasheetV2 td input {
    padding: 0;
    margin: 0;
    width: 100%;
    font-size: 14px;
    border-radius: 0;
    /*border: 1px solid blueviolet;*/
    border: none;
    /*border: none;*/
    min-height: 22px !important;
    max-height: 22px !important;
}


.resizer {
    cursor: col-resize;
    position: absolute;
    top: 0;
    right: 0;

    height: 100%;
    width: 0.25rem;

    background: transparent;

    &:hover {
        background: var(--mantine-color-blue-2);
    }
}
